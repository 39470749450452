<template>
    <div>
      <page-title :heading=heading :subheading=subheading></page-title>
      
      <!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Sale Details</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" :href="`/shop-management/sales`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>

			
			<div class="pb-0 mb-0 mt-3">
			
				<a-row :gutter="24" class="pb-4">
					<a-col :span="24" :md="12" class="pb-4">
						<label><strong>Customer Name</strong></label>
						<a-input 
							disabled
							v-model="saleDetails.customer"
							/>
					</a-col>

					<a-col :span="24" :md="12">
						<label><strong>Payment Method</strong></label>
						<a-input 
							disabled
							v-model="saleDetails.method"
							/>
					</a-col>
				</a-row>
			
				<div class="mt-5">
					<a-row type="flex" :gutter="24" class="pb-4">
						<a-col :span="24" :md="24">
							<a-table class="mt-20 pb-2"  
								size="middle"
								:responsive="true"
								:bordered="false"
								:columns="columns" 
								:data-source="saleDetails.items" 
								:rowKey="record => record.uuid"
								:pagination="false">

									<template slot="s_no" slot-scope="s_no, record, index">
										{{ index + 1 }}
									</template>

									<template slot="price" slot-scope="price, record">
										{{ record.product.price.price }}
									</template>

									<template slot="createdAt" slot-scope="createdAt">
										{{ $Moment(createdAt).format("DD MMM YYYY - hh:mm:ss A ") }}
									</template>
								
							</a-table>
						</a-col>
					</a-row>
				</div>


				<a-row :gutter="24" class="mt-3 pb-5">
					<a-col :span="24" :md="13">
						
					</a-col>

					<a-col :span="24" :md="7" class="pb-5">
						<label>Total Amount: <strong>{{ saleDetails.totalAmount }}/= Tshs</strong></label>
						
					</a-col>

					
				</a-row>
					
			</div>
		</a-card>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'PRODUCTS',
			dataIndex: 'product.name',
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'SELLING PRICE(@)',
			dataIndex: 'price',
			scopedSlots: { customRender: 'price' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'QUANTITY',
			dataIndex: 'quantity',
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'DISCOUNT',
			dataIndex: 'discount',
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'SUBTOTAL',
			dataIndex: 'amount',
			// width: "8%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
			// width: "10%"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Settings',
            subheading: 'Medicine Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            pagination: {
                search: '',
                loading: false,
                perPage: 10,
                currentPage: 1,
                perPageOptions: [
                    {label: "10", value: 10}, 
                    {label: "20", value: 20}, 
                    {label: "30", value: 30}, 
                    {label: "50", value: 50}
                ],
                total: 10
            },

            columns,
            message: '',

            medicines: [],
            
            form: '',
            rules,
            saleDetails: {
                uuid: null,
                companyUuid: null,
                customer: null,
                method: null,
				items: [],
				totalAmount: 0.0,
                isCreate: true,
                loading: false,
                showModal: false,
            },


            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

            notify: {
                text: null,
                type: null,
            },
        }
		},
		created() {
            this.getUserDetails();
			this.getSaleDetails();
		},
		methods: {

            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },


			getTotalAmount(items) {
				let totalAmount = 0.0

				items.map(item => {
					totalAmount += parseFloat(item.amount)
				});

				this.saleDetails.totalAmount = totalAmount.toFixed(2).toLocaleString("en-US");
			},

			async getSaleDetails() {

                let url = `${this.$BACKEND_URL}/sales/show/${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.saleDetails.uuid = response.data.uuid;
						this.saleDetails.customer = response.data.customer;
						this.saleDetails.method = `${response.data.method[0].toUpperCase()}${response.data.method.substring(1)}`;
						this.saleDetails.items = response.data.items;

						this.getTotalAmount(response.data.items)
                    }
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)
                });
            },


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>